import React from 'react';
import tMCollage from '../../assets/Moms-Collage.png';

export default function Gallery() {
	return (
		<>
			<img src={tMCollage} alt="Tired Moms" className="colTMtwo" />
		</>
	);
}
